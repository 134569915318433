
.chat-input-holder {
    flex: 0 0 100px 0; 
    justify-content: center;
    align-items: center;
  }

.chat-input-form {
    display: flex;
    align-items: center;
}
  
  .chat-input-textarea {
  
    background-color: #40414f;
    padding:8px;
    width: 90vh;
    border-radius: 5px;
    color:white;
    font-size: 1.0em;;
    border: none;
    margin: 12px;
    outline: none;
    box-shadow: 0 0 8px 0 rgba(0,0,0,0.5);
  }
  
  
  .submit-button {
    background-color: rgba(255,255,255,0.0);
  
    border: 1px solid gray;
    border-radius: 5px;
    color: white;
    padding: 6px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    color: white;
    background-color:  rgba(255,255,255,0.1);
    border: 1px solid gray;
    border-radius: 5px;
  }
  