html, body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  margin: 0;
  font-family: Calibri, sans-serif;
}
/* target the scrollbar for webkit-based browsers */
::-webkit-scrollbar {
  width: 8px; /* width of the scrollbar */
}

/* track - the background of the scrollbar */
::-webkit-scrollbar-track {
  background-color: #rgba(255,255,255,0.0);;
}

/* thumb - the draggable handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background-color: #444654;
}

.App {
  display:flex;
  flex-direction: column;
  background-color: #282c34;
  color:white;
}
/* Add a black background color to the top navigation */
.topnav {
  background-color: #333;
  /* overflow: hidden; */
  text-align: left;
  white-space: pre-line;
  height: 40px;
  padding: 8px;
  background-color: #202123;
}

/* format the html select dropdown as black background and white text*/
.modelselection{
  margin: 0 5px;
  text-align: left;
  background-color: #202123;
  color: white;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
}
/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.mainbody {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.sidemenu {
  flex: 1;
  flex-direction: column;
  min-width: 260px;
  max-width: 260px;
  padding:10px;
  background-color: #202123;

}


.sidemenu-button {
  padding: 12px;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: left;
  transition:ease 0.25s;
}

.sidemenu-button:hover {
  background-color:  rgba(255,255,255,0.1);
  padding: 12px;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: left;
}

.sidemenu-button span {
  padding-left:6px;
  padding-right: 12px;
}

.signout-button {
  color: white;   /* Set the text color to white */
  background-color: #202123;
  width: 100%;
  padding: 12px;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: center;
  transition:ease 0.25s;
  align-self:flex-end;
}

.signout-button:hover {
  background-color:  rgba(255,255,255,0.1);
  border: 1px solid gray;
}

.saved-chat-logs select {
  background-color: #202123;
  color: white;
  border: 1px solid gray;
  border-radius: 5px;
  min-width: 240px;
  max-width: 240px;
  padding:10px;
}

.saved-chat-logs select:focus {
  outline: none;
  
}

.saved-chat-logs select option {
  padding: 3px;
}

.saved-chat-logs select option:focus, .saved-chat-logs select option:checked {
  background-color: gray;
  color: black;
}


.chatbox {
  background-color: #343541;
  flex-direction:column;
  display: flex;
  align-items:center;
  height: 95vh;
  width: 100vw;
}

.chat-log {
  flex: 1 0 1px 1;
  text-align: left;
  overflow-y: scroll;
  width: 90vh;
}

.chat-message-center {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding: 12px;
  padding-left: 40px;
  padding-right: 40px;
}

.chat-message {
  background-color:  rgba(255,255,255,0.0);

}

.chat-message.assistant {
  background-color: #444654;
  
}

.avatar {
  flex: 1;
  background: white;
  border-radius:  50%;
  max-width: 30px;
  max-height: 30px;

}

.avatar.assistant {
  flex: 1;
  background: #0da37f;
  border-radius:  50%;
  max-width: 30px;
  max-height: 30px;

}

.message {
  flex: 1;
  padding: 6px;
  white-space: pre-wrap;
  overflow-wrap: break-word; 

}

.App-link {
  color: blue;
}
