.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip:hover .tooltip-text {
    visibility: visible;
  }
  .tooltip-text {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align:left;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 999;
    top: 100%;
    left: 120%;
    transform: translateX(-50%);
    white-space: normal;
    font-size: 14px;
    bottom: auto;
    max-width: 400px; 
    min-width: 250px; 
    word-wrap: break-word; 
  }
  
  .tooltip-text::after {
    content: "";
    position: absolute;
    top: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }
  
  